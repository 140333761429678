import React from "react";
import './postCard.css';

function PostCard(props) {
	return (
		<div className="post-card">
			<a href ={props.url} style={{textDecoration:'none'}}>
			<div className="img">
				<img src={props.img} alt="blog_post" />	
			</div>
			<div className="title">{props.title}</div></a>
			<div className="description">{props.description}</div>
			<a href ={props.url} className="read-more">Read more</a>
		</div>
	);
}
export default PostCard;
