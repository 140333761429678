import React from "react";
import {Link} from "react-router-dom";
import whitelogo from "../../images/whitelogo.png";
import qrcode from "../../images/multi_linkqr.png";
import "./footerNewInfra.css";


function FooterNewInfra() {
  return (
    <div className="container py-4 contactus footer-new-infra">
      <div className="row">
        <div className="col-md-3" id="footer-add-logo">
        <img className="logo"  src={ whitelogo} alt="" />
        <h4>Ciphercode Tech Solutions Pvt. Ltd.<br></br>
          IndiQube Delta, Sector 5, HSR<br></br>Bengaluru – 560034 India</h4>
        </div>
        <div className="col-md-5">
          <table className="table footer-table">
           <tbody>
              <tr>
                <td><Link  to='/aboutus'>  About us </Link></td>
                <td><Link to="/qrcode/url">Cryptoblock QR</Link></td>
                <td><Link to="/career"> Careers</Link></td>
              </tr>
              <tr>
                <td><Link to="product/qSeal"> Qseal<sup>®</sup></Link></td>
                <td><Link to="/resources"> Resources</Link></td>
                <td><Link to="/sitemap"> Sitemap</Link></td>
              </tr>
              <tr>
                <td><Link to="product/qReach"> QReach<sup>®</sup></Link></td>
                <td><Link to="/web_3.0"> Web 3.0</Link></td>
                <td><Link to="/contactus"> Contact us</Link></td>
              </tr>
              <tr>
                <td><Link to="product/qReport">QReport<sup>®</sup></Link></td>
                <td><Link to="/schedule_demo">Get demo</Link></td>
                <td><Link to="/privacy">Privacy Policy</Link></td>
              </tr>
              </tbody>
          </table>
        </div>
        <div className="col-md-4">
          <table className="table footer-table">
            <tbody>
              <tr>
                <td><h3>Contact Us</h3></td>
                <td><h3>Scan or Click</h3></td>
              </tr>
              <tr style={{marginTop:'5px'}}>
                <td>Phone <div style={{color:'blue'}}>+91-9741111594</div>
                </td>
              </tr>
              <tr>
                <td>Email<div style={{color:'blue', textDecoration:'underline'}}><a href="mailto:sales@ciphercode.co" target="_blank" rel="noreferrer">sales@ciphercode.co</a></div></td>
                <td><a href="http://bwqr.me/01/MLQ/nqcZxuhsyaTWJimi" target="_blank" rel="noreferrer"><img className="qrlogo"  src={ qrcode} alt="" /></a></td>
              </tr>
            </tbody>
          </table>
        </div>
        
      </div>
      <div className="copyright">
        <small>Copyright &copy; 2024. All rights reserved.</small>
      </div>
    </div>
  );
}

export default FooterNewInfra;
