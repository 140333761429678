import React from 'react'
import { Link } from 'react-router-dom';
import icons from "../../images/favicon.png";

export default function getdemo() {
  return (
    <>
      <div>
        <div className="footer">
          <div className="container">
            <img src={icons} alt="" />
            <h2 className='footerTwo'>Getting started is easy</h2>
            <p>Switch to Smart Packaging Today!</p>
            <Link to="/schedule_demo"><button className="btn btn-BlueBg btn-explore">Get a demo</button></Link>
          </div>
        </div>
      </div>
    </>
  )
}
