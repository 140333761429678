import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import img6 from "../../images/wine_bottle1.png";
import img5 from "../../images/wine_bottle2.png";
import img4 from "../../images/left_arrow.png";
import img3 from "../../images/right_arrow.png";
import img2 from "../../images/quata_left.png";
import './topHead.css'


function Webslider() {
    const options = {
        responsiveClass: true,
        nav: true,
        loop:true,
        autoplay: true,
        dots: false,
        smartSpeed: 1000,
        responsive: {
          0: {
            items: 1,
          },
          768: {
            items: 1,
          },
          1170: {
            items: 2,
          },
        },
      };
    return (
    <div class="webslider">
      <div className="row">
        {/*<div className="owl-control">
            <img src={img4} alt="" /> 
            <img src={img3} alt="" />
    </div>*/}
        <OwlCarousel {...options} id="customers-testimonials" className="owl-carousel">
	    <div className="item">
        <div className="col">
		     <img src={img6} alt="1000X1000" />
	    </div>
        </div>
        
        <div className="item">
        <div className="col">
		<img src={img5} alt="" />
	    </div>
        </div>
       </OwlCarousel>
     </div>
     <div className="container webslide">
        <img src={img2} alt="" />
        <h1>Anything physical can be copied,<br></br> true <span className='tech-stack'>authencity</span>  comes from cryptography</h1>
        <h6>-NIST</h6>
        <p>A technology stack with digital authentication at core is the enabler of inevitable <br></br> transformations such as Physical NFTs, Virtual experiences and more...</p>
     </div>
  </div>
)
}
export default Webslider;