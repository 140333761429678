import React, {useEffect}  from "react";
import TopHead from "../components/Footwear/tophead";
import Challenges from "../components/Footwear/challenges";
import Boost from "../components/Footwear/boost";
import Utilise from "../components/Footwear/utilise";
import Feedback from "../components/Footwear/feedback";

export default function Footwear() {
    useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	});
    return (
      <>
         <TopHead />
         <Challenges/>
         <Boost/>
         <Utilise/>
         <Feedback/>
         
      </>
    )
}
   