import React from "react";
import './tophead.css'
import img from "../../images/Elemage1.png";
import img1 from "../../images/ticked.png";
import img2 from "../../images/merge_img.png";

export default function TopHead() {
	return (
		<>
        <div className="container personalcare-tophead">
        <div className="personal-content">
            <div className="row">
                <div className="col-md-6">
				<div className="image-view">
				    <img src={img} alt=""/>
				    <button className="para-content">Premium Personal care</button>
				</div>
                <h2>Beyond QR Codes</h2>
                <ul>
                    <li><img src={img1} alt=""  style={{marginRight:'15px'}}/>Innovative Product Authentication</li>
					<li><img src={img1} alt=""  style={{marginRight:'15px'}}/>Dynamic Consumer Engagement</li>
					<li><img src={img1} alt=""  style={{marginRight:'15px'}}/>Unit Level Control</li>
					<li><img src={img1} alt=""  style={{marginRight:'15px'}}/>AI Driven Insights</li>
                </ul>
				<p>In the competitive global pharma landscape, <b>ELEMAGE WELLNESS LLP,</b> a top personal care brand acknowledges that delivering a top-tier skincare product isn't enough in a Consurner Connected Era. Trust, Authenticity, and Direct Consumer Engagement are essential too. Traditional QR codes fell short of their multifaceted requirement for export product packaging, prompting the brand to look for a groundbreaking solution.</p>
                </div>
                <div className="col-md-6">
				    <div className="position-imagefix row">
					<img src={img2} alt=""  class="image-fluid"/>
				    </div>
                </div>
            </div>
        </div>
		</div>
		
		<div style={{backgroundColor:'#060918',  paddingTop:'10px', paddingBottom:'10px',}} className="personal-data">
		<div className="container">
        <div className="row personal-contentfull">
			<div class="col-md-3 sm-6 quard">
                <h2>8+ SKUs</h2>
				<p>Digitized</p>
			</div>
			<div class="col-md-3 sm-6 quard">
				<h2>GS1</h2>
				<p>Compliant</p>
			</div>
			<div class="col-md-3 sm-6 quard">
				<h2>80%</h2>
				<p>Higher Engagements</p>
			</div>
			<div class="col-md-3 sm-6 quard">
				<h2>21k</h2>
				<p>Consumer Touch Points</p>
			</div>
		</div>
		</div>
		</div>
	</>
	);
}