import React from "react";
import img26 from "../../images/2 (26).png";
import img25 from "../../images/2 (1).png";
import img from "../../images/maptree.png";
import {Link} from "react-router-dom";
import './topHead.css'
import { BsArrowRightShort } from "react-icons/bs";

function TopHead() {
	return (
		<div style={{background: "linear-gradient(0.63deg, rgba(49, 87, 239, 0.1) 1.58%, rgba(49, 87, 239, 0) 97.39%)"}}>
			<div className="container fb top-head">
				<img alt="brandwise" className="absBg1" style={{opacity: 0.21}} src={img26} />
				<img alt="brandwise" className="absBg2" style={{opacity: 0.23}} src={img25} />
				<div className="row">
					<div className="col-12 col-md-4">
						<h3>With Us, <br/> You Leap from</h3>
						<br/><br/>
						<h1>Physical Universe <br />to <span className="metaverse"><b>Metaverse</b></span></h1>
						<h4>Move form e-commerce to immersive commerce, Physical universe to metaverse, instant gratifications to token assets!</h4>
						<Link  to='/contactus'><button className="fb-button">Connect with us <BsArrowRightShort /></button></Link>
					</div>
					<div className="col-12 col-md-7 align-self-center position-relative mt-5 mt-md-2" >
						<img className="main-img" src={img} alt="main_image" />
					</div>
				</div>
			</div>
		</div>
	);
}
export default TopHead;
