import React, {useEffect}  from "react";
import TopHead from "../components/Retail/tophead";
import Challenges from "../components/Retail/challenges";
import ROI from "../components/Retail/roi";
import Brand from "../components/Retail/brand";
import Feedback from "../components/Retail/feedback";


export default function Retail() {
    useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	});
    return (
      <>
         <TopHead />
         <Challenges/>
         <ROI/>
         <Brand/>
         <Feedback/>
      </>
    )
}
   