import React, {useEffect} from "react";
import TopHead from "../components/Pricing/top-head";
import Features from "../components/Pricing/features";


export default function Pricing() {
	useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
	return (
		<div>
			<TopHead />
			<Features />
		</div>
	);
}

