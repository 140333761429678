import React, {useEffect} from "react";
import TopHead from "../components/Product/top-head";
import ProductDigital from "../components/Product/prod-digi";
import Discover from "../components/Product/discover";
import SupportApp from "../components/Product/support-app";
import Footerhome from '../components/Home/footerhome';

function Product() {
  useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
  return (
    <div>
      <TopHead></TopHead>
      <ProductDigital></ProductDigital>
      <SupportApp></SupportApp>
       <Discover></Discover>
       <Footerhome></Footerhome>
    </div>
  );
}
export default Product;
