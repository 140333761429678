import React  from 'react';
import img3 from "../../images/polygon-72.svg";
import img5 from "../../images/group-159@2x.png";
import img6 from "../../images/profiles.png";
import img7 from "../../images/health_lock.svg";
import img8 from "../../images/vector.svg";
import img2 from "../../images/vector1.svg";
import img9 from "../../images/bitcoin.png";
import './webplatform1.css'


function WebPlatfrom1() {
return (   
<div className='webplatform'> 
<div className="circle_left">
<img className="profiles-icon" alt="" src={img6} />
</div>
<div className="managemet_body">
    <h4 className='with_us container'>Brand management today is about enabling a dialogue between business & its consumers, authentic products make this job simple. Ciphercode brings authenticity at scale.</h4>
    <div className="managemet_group">
    <img src={img5} alt="" />
    </div>
    <div className="managemet_bitcoin">
    <img src={img9} alt="" />
    </div>
    <div className="row managemet_box">
            <div className="col-12 col-md-4 ellipse-box">
                <img src={img7} alt="" />
                <p>Shareholder Capitalism</p>
                <p>to</p>
                <p>Stakeholder Capitalism</p>
            </div>
            <div className="col-12 col-md-4 ellipse-box">
                <img src={img2} alt="" />
                <p>Truest</p>
                <p>means</p>
                <p>Ciphercode</p>
            </div>
            <div className="col-12  col-md-4 ellipse-box">
                <img src={img8} alt="" />
                <p>Invest your Incentives</p>
                <p>&</p>
                <p>Build tradable assets</p>
            </div>
        </div>
    </div>
<div className="ellipse_rigth">
    <img  alt="" src={img3} />
</div>
</div>
  )
}
export default WebPlatfrom1