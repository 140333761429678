import React  from 'react';
import './webplatform3.css'
import img3 from "../../images/ellipse-42.svg";
import img4 from "../../images/demoza.png";
import img5 from "../../images/aditya_birla.png";
import img6 from "../../images/vanheusen.png";
import img7 from "../../images/demoza_ellipse.svg";
import { BsArrowRightShort } from "react-icons/bs";

function WebPlatfrom3() {
return (  
<div className="webplatform3">
<div className="ellipse_rigth">
    <img  alt="" src={img3} />
</div>
<div className="container">
    <div className="webplat-3">
        <h1>How our <span className='tech-stack'> Tech-Stack </span>helped <br></br>Brands at scale</h1>
        <p>Authentic physical objects bring users close to their favorite brands, users dictate & monetize their<br></br>Persona. Businesses who understands the potential, are unstoppable in the decentralized era.</p>
    </div>
    <div className="frame-div">
        <div className="row">
            <div className="col-12 col-md-3">
            <div className="consumers-container">
                <div className="demoza-box">
                 <img className="group-child4" alt="" src={img4} />
                </div>
                <span className="to">Consumers are the celebrities of our brand, we are centralized around them, In the decentralized era, we empower consumers.</span>
              <div className="demoza-use-case">Demoza, Use Case</div>
            </div>
          </div>
          <div className="col-12 col-md-5">
          <div className="wine-industry-container">
          <div className="rico-box">
            <img className="frame-child1" alt="" src={img5} />
          </div>
           <span className="to">Wine industry is getting transformed across the globe with modern consumer communities and their preferences, we couldn’t have met their expectations and made them part of the business without Ciphercode’s technology stack from authentication to tokenization and monetization.</span>
            <div className="rico-use-case">Rico, Use Case</div>
            </div>
          </div>
          <div className="col-12 col-md-3">
          <div className="demanding-consumers-container">
          <div className="vanhusen-box">
            <img className="frame-child1" alt="" src={img6} />
          </div>
          <span className="to">Demanding consumers buy experience over the price, having technology partners such as Ciphercode made our rewarding.</span>
            <div className="guess-india-use">Guess India, Use Case</div>
          </div>
        </div>
        </div> 
        {/* <button>See more <BsArrowRightShort /></button>          */}
    </div>
</div>

</div>

    )
}
export default WebPlatfrom3