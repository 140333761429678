import React, { useEffect } from "react";
import './tophead.css'
import img1 from "../../images/footwear_banner.jpeg";
import img2 from "../../images/Footwear 1.png";

export default function TopHead() {
	return (
		<>
        <div className="pt-5">
           <img className="cover-image" src={img1} alt="cover_pic"/>
        </div>
        <div className="container footwear-tophead">
        <div className="footwear-content">
            <div className="row">
                <div className="col-md-6 order-2 order-md-2 ">
                <h2>Step Up Your Game: Sole-fully <span> Attract</span> More Customers</h2>
                <p>Unlock the full potential of your marketing
                    campaigns with Brandwise’s powerful
                    Engagement Tools. Collect, analyze, and utilize
                    customer data like never before. Safeguard your
                    customers with secure authentication of your
                    products.
                </p>
                <br></br>
                <div className="row">
                    <div className="col">
                        <input type="text" placeholder="Full Name"/>
                    </div>
                    <div className="col">
                        <input type="text" placeholder="Business Email"/>
                    </div>
                </div>
                <button type="button">Connect with an expert</button>
                </div>
                <div className="col-md-6 order-1 order-md-2 " style={{textAlign:"right"}}>
                    <img src={img2} alt="" style={{width:"100%",height:"100%"}}/>
                </div>
            </div>
        </div>
          <br></br>
        <div className="highlight-content" id="challengesButton">
                <div className="row">
                    <div className=" col-6 col-md-2">
                        <a href="#challengesContent"><button>Challenges</button></a>
                    </div>
                    <div className="col-md-1">
                        <div class="vertical-line"></div>
                    </div>
                    <div className="col-6 col-md-3">
                        <a href="#boostContent"><button>What You Get</button></a>
                    </div>
                    <div className="col-md-1">
                        <div class="vertical-line"></div>
                    </div>
                    <div className="col-6 col-md-2">
                      <a href="#useContent"><button>How to use</button></a>
                    </div>
                    <div className="col-md-1">
                        <div class="vertical-line"></div>
                    </div>
                    <div className="col-6 col-md-2">
                        <a href="#feedbackContent"><button>Testimonies</button></a>
                    </div>
                </div>
          </div><br/><br/>
		</div>       
        </>
	);
}