import React, {useEffect} from "react";
import './sitemap.css';
import {Link} from 'react-router-dom'


export default function Sitemap() {
    useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
      const headStyles = {
        marginTop:'150px',
        TextAlignment: 'center',
        fontWeight: '900',
        color:'black',
        
      }
    return (
        <div className="container sitehead">
        <h1 style={headStyles}>Sitemap</h1><hr />
        <h3>About Ciphercode<sup>®</sup></h3>
        <ul>
            <li><Link to ="/">Brandwise<sup>®</sup></Link></li>
            <li><Link to = "/contactus">Contact us</Link></li>
            <li><Link to = "/aboutus">About us</Link></li>
            <li><Link to = "/signup">Sign-up</Link></li>
            <li><Link to = "/privacy">Privacy policy</Link></li>
            <li><Link to = "/schedule_demo">Get Demo</Link></li>
        </ul>< hr />
        <h3>Product</h3>
        <ul>
            <li><Link to ="/product/qSeal">Qseal<sup>®</sup></Link></li>
            <li><Link to ="/product/qReach">QReach<sup>®</sup></Link></li>
            <li><Link to ="/product/qReport">QReport<sup>®</sup></Link></li>
            <li><Link to ="/pricing">Pricing</Link></li>
            </ul><hr />
        <h3>Information Resources</h3>
        <ul>
            <li><Link to ="/homeapplicances">Home Appliances</Link></li>
            <li><Link to ="/blog">Blog</Link></li>
            <li><Link to ="/encryption">Encryption</Link></li>
            <li><Link to ="/web_3.0">Web 3.0</Link></li>
            <li><Link to ="/personalcare">Personal Care</Link></li>
            <li><Link to ="/automobile">Automobiles</Link></li>
            <li><Link to ="/resources">Cryptoblocks</Link></li>
            <li><Link to ="/career">Careers</Link></li>
        </ul><hr />
        <h3>Cryptoblocks QR Generate</h3>
        <ul>
            <li><Link to ="/qrcode/url">Website Link/Url</Link></li>
            <li><Link to ="/qrcode/whatsapp">WhatsApp</Link></li>
            <li><Link to ="/qrcode/social_meida">Social Media</Link></li>
            <li><Link to ="/qrcode/File_qr">PDF/Media File</Link></li>
            <li><Link to ="/qrcode/location_qr">Location</Link></li>
            <li><Link to ="/qrcode/campaign">Campaign</Link></li>
        </ul>
        </div>
    )
}