import React  from 'react';
import img6 from "../../images/ellipse-42.svg";
import img4 from "../../images/polygon-7.svg";
import './webplatform5.css'


function WebPlatfrom5() {
    return (  
    <div className="webplatform5">
        <div className="elipse_left">
          <img className="profiles-icon" alt="" src={img4} />
        </div>
        <div className="managemet_btw container">
        <h1>How <span className='tech-stack'>Everythings</span> Works ?</h1>
        <p>Subscribe to Ciphercode’s Brandwise platform, exercise never before <br></br>product authentication and consumer engagement solutions on fingertips</p>
        <div className="row">
        <div className="col ellipse-btw1">
          <h4>Digitize Products at<br></br> scale, yet at unit level</h4>
          <h6>Every unit produced gets<br></br> unique immutable ID to be attached with product in customizable way</h6>
        </div>
        <div className="col ellipse-btw2">
        <h4>Entice <br></br>Consumers</h4>
        <h6>Let consumers get the <br></br>experiences what they <br></br>want to pay for</h6>
        </div>
        <div className="col ellipse-btw2">
         <h4>Monetize <br></br>Insights</h4>
         <h6>Build unique insights, <br></br>invoke token economy</h6>
        </div>
        </div> 
        </div>
        <div className="circle_rigth">
           <img  alt="" src={img6} />
        </div>
    </div>
    )
}
export default WebPlatfrom5