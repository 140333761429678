import React,{useState}   from 'react';
import { BsArrowRightShort } from "react-icons/bs";
import './websubscribe.css'


function WebSubscribe() {
    const [email, setEmail] = useState("");
	const[error,seterror]=useState(false);

	async function subscribe(){
        let item = {email}
		if(email.trim("").length === 0 ){
			seterror(true)
		  }
		else{
		 seterror(false)
        fetch("https://web.ciphercode.co/api/v1/subscribe/", {
            method : 'POST',
            body: JSON.stringify(item),
            headers : {
                "Content-Type" : 'application/json',
                "Accept" : 'application/json',
            }
        })
        .then(response => response.json())
		.then(response => {
			if(response.message === "Your data successfully submitted"){
				alert('Thank you for subscribing with us')
				setEmail("")
			}
		}).catch((error)=>{
			console.log(error)
		})
    }
}
    return (  
    <div className="websubscribe">
        <div className="container">
        <div className="row">
            <div className="col-12 col-md-6">
                <h1>Subscribe to Our <br></br>Newsletter</h1>
                <p>and never miss an update from <br></br>the future</p>
            </div>
            <div className="col-12 col-md-6">
                <form action="">
                <input type="email" name="email" placeholder="Email Address"  autoComplete='off' value={email} onChange = {(e) => setEmail(e.target.value)} />
					{error&&<h6 style={{color:'white', textAlign:'left'}}>Please, enter email address</h6>}
					<button className="btn btn-primary" type="button" onClick={subscribe}>Subscribe <BsArrowRightShort /></button>
                </form>
            </div>
        </div>
        </div>  

    </div>
    )
}
export default WebSubscribe