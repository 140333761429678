import React from "react";
import './tophead.css';
import img1 from "../../images/partner11.png";
import img2 from "../../images/partner2.png";
import img3 from "../../images/partner3.webp";
import img4 from "../../images/partner4.png";

export default function TopHead() {
    return (
        <>
            <div className="container partnership-tophead">
                <div className="partnership-content">
                    <h2><span>Partnership</span> Program</h2>
                    <p className="partner-para">Let's grow together</p>
                    <div className="row">
                        <div className="col-md-6 order-md-2">
                            <img src={img1} alt="" style={{ width: "100%", height: "100%" }} />
                        </div>
                        <div className="col-md-6 order-md-2">
                            <div className="box-highlight">
                                <h3><span>Sales</span> Partner</h3>
                                <p>Our Sales Partnership program empowers
                                    you to take Brandwise to your network,
                                    partners and customers.<br /><br />Your referral code used by the prospect
                                    customer shall help us to serve our
                                    partnership</p>
                            </div>
                        </div>
                    </div><br /><br />
                    <div className="row">
                        <div className="col-md-6 order-2 order-md-2">
                            <div className="box-highlight">
                                <h3><span>Business </span>Partner</h3>
                                <p>Our Business Partnership program invites
                                    you to collaborate with Brandwise to offer
                                    Integrated, unified experience and
                                    multiplied value to our common customer.<br /><br />
                                    We'll have the opportunity to consult and
                                    implement these solutions effectively
                                    wherever we go, or you go.</p>
                            </div>
                        </div>
                        <div className="col-md-6 order-1 order-md-2">
                            <img src={img2} alt="" style={{ width: "100%", height: "100%" }} />
                        </div>
                    </div><br /><br />
                    <div className="row">
                        <div className="col-md-6 order-1 order-md-2">
                            <img src={img3} alt="" style={{ width: "100%", height: "100%" }} />
                        </div>
                        <div className="col-md-6 order-2 order-md-2">
                            <div className="box-highlight">
                                <h3><span>Referral</span> Partner</h3>
                                <p>Our Referral Partnership program enables
                                    you to become affiliated with us by
                                    referring Brandwise to prospects.</p>
                            </div>
                        </div>
                    </div><br /><br />
                    <div className="row">
                        <div className="col-md-6 order-1 order-md-2">
                            <div className="box-highlight">
                                <h3><span>Responsibilities</span></h3>
                                <ul>
                                    <li>Actively engage in educating prospective
                                        customers on Brandwise value proposition.</li>
                                    <li>Provide accurate information about
                                        Brandwise's offerings and capabilities to
                                        potential clients.</li>
                                    <li>Assist in nurturing and maintaining
                                        relationships with referred prospects
                                        throughout the sales process.</li>
                                    <li>Collaborate with Brandwise's sales and
                                        marketing teams to ensure a seamless
                                        transition and customer success.</li>
                                    <li>Help identify and qualify leads that align
                                        with Brandwise's target customer profiles.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 order-2 order-md-2">
                            <img src={img4} className="response" alt="" style={{ width: "100%", height: "100%", }} />
                        </div>
                    </div><br /><br />
                   
                </div>
            </div>

        </>
    );
}