import React from "react";
import './tophead.css'
import img from "../../images/industry1.jpg";

export default function TopHead() {
	return (
		<>
        <div className="container packing-tophead">
        <div className="packing-content">
            <div className="row">
                <div className="col-md-6">
                <h2>Empower the “Smart” in Smart Packaging</h2>
				<p>Being smart in packaging industry demands a
                   blend of <span>intelligence, connectivity,</span> and
                    <span> sustainability</span>. Achieving this trifecta in the era of
                   experience economy is a formidable challenge.
                   Brandwise® by Ciphercode is transforming the way
                   smart packaging empowers their customers.
                </p>
                <br></br>
                <div className="row">
                    <div className="col">
                        <input type="text"  placeholder="Full Name"/>
                    </div>
                    <div className="col">
                        <input type="text" placeholder="Mobile Number"/>
                    </div>
                </div>
                <button type="button">Connect with an expert</button>
                </div>
                <div className="col-md-6" style={{textAlign:"right"}}>
					<img src={img} alt="" style={{width:"85%"}}/>
                </div>
            </div>
        </div>
          <br></br>
        <div className="highlight-content">
                <div className="row">
                    <div className="col-md-4">
                        <a href="">
                        <button>Highlights</button>
                        </a>
                    </div>
                    <div className="col-md-1">
                        <div class="vertical-line"></div>
                    </div>
                    <div className="col-6 col-md-3">
                        <button>Use Cases</button>
                    </div>
                    <div className="col-md-1">
                        <div class="vertical-line"></div>
                    </div>
                    <div className="col-6 col-md-3">
                        <button>Testimonies</button>
                    </div>
                </div>
          </div>
		</div>
        
        </>
	);
}