import React, {useEffect}  from "react";
import TopHead from "../components/Careers/tophead";
import EmployeeSay from "../components/Careers/employeesay";
import TalkBusiness from "../components/Careers/talkbusiness";

export default function Careerpage() {
    useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
    return (
      <>
        <TopHead />
        <EmployeeSay />
        <TalkBusiness />
      </>
    )
}