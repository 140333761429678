import React, {useEffect} from "react";
import TopHead from "../components/Blognumberthree/topHead";
import BlogContent from "../components/Blognumberthree/blogcontent";

function Blog() {
	useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
	return (
		<div>
			<TopHead />
			<BlogContent />
		</div>
	);
}
export default Blog;
