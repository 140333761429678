import React, {useEffect} from "react";
import TopHead from "../components/Blognumbersix/tophead";
import BlogContent from "../components/Blognumbersix/blogcontent";

function Blogpost6() {
	useEffect(() => {window.scroll({top: 0, behavior: 'smooth'})});
	return (
		<div>
			<TopHead />
			<BlogContent />
		</div>
	);
}
export default Blogpost6;