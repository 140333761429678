import React, {useEffect}from 'react';
import TopHead from "../components/Home/top-head";
import Feedback from "../components/Home/feedback";
import Footertwo from '../components/Home/footerhome';
import Potential from '../components/Home/potential';
import AgeChallenge from '../components/Home/agechallenge';
import BrandDiff from '../components/Home/branddiff';
import Transformation from '../components/Home/transformation';
import Discover from '../components/Home/discover';

function Home() {
    useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
    return (
        <div>
          <TopHead></TopHead>
          <Potential></Potential>
          <AgeChallenge></AgeChallenge>
          <BrandDiff></BrandDiff>
          <Transformation></Transformation>
          <Feedback></Feedback>
          <Discover></Discover>
          <Footertwo></Footertwo>
        </div>
    )
}
export default Home;
