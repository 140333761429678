import React, {useEffect}  from "react";
import TopHead from "../components/Packaging/tophead";
import Seamless from "../components/Packaging/seamless";
import Package from "../components/Packaging/package";
import GetDemo from "../components/Packaging/getdemo";
import Feedback from "../components/Home/feedback";


export default function Packaging() {
    useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	});
    return (
      <>
         <TopHead />
         <Seamless />
         <Package />
         <Feedback></Feedback>
         <GetDemo/>
      </>
    )
}
   