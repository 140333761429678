import React from 'react'
import img1 from '../../images/Supported_Applications-transparent (1).png';

import './support-app.css';



export default function supportapp() {
    return (
        <div className="sup-app">
            <div className="container application-content">
              <h2><span>Supported Applications!</span></h2>
              <h4>Seamless Integrations So you Don’t Have to Worry</h4>
              <img src={img1} alt="inosys"  style={{height:'100%',width:"100%"}}/>
            </div><br /><br /><br />
        </div>
    )
}
