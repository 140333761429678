import React, {useState} from 'react';

const Popup=(props)=>{
  const [name, setName]=useState("");
  const [company, setCompany] = useState(null);
  const [phone, setPhone] = useState("");
  const[error,seterror]=useState(false);
  const [isInputphoneValid,setisInputPhoneValid]=useState(true);
  const [isInputNameValid,setisInputNameValid]=useState(true);

  const regextPhoneValidation = Phone => {
    const regex = new RegExp('^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$');
    return regex.test(Phone);
};
const regextNameValidation = Name => {
  const regex = new RegExp('[a-zA-Z]+\\.?');
  return regex.test(Name);
};
const updatePhoneVal = event => {
  const { value } = event.target;
  const isInputValid = !value || regextPhoneValidation(value)
  setisInputPhoneValid(isInputValid)
  setPhone(value)
};
const updateNameVal = event => {
  const { value } = event.target;
  const isInputNameValid= !value || regextNameValidation(value)
  setisInputNameValid(isInputNameValid)
  setName(value)
};
    
  async function requestcall(){
      let item = {name, phone, company,}
      if(name.trim("").length === 0 ){
        seterror(true)
      }
    
      else if(phone.trim("").length === 0 ){
        seterror(true)
      }
      else if(isInputphoneValid === true && isInputNameValid === true){
        seterror(false)
        fetch("https://web.ciphercode.co/api/v1/request_call/", {
          method : 'POST',
          body: JSON.stringify(item),
          headers : {
              "Content-Type" : 'application/json',
              "Accept" : 'application/json',
          }
      })
      .then(response => response.json())
  .then(response => {
    if(response.message === "Your data successfully submitted"){
      alert('Sure, we will call you!')
      setName("")
      setCompany("")
      setPhone("")
    }
  }).catch((error)=>{
    alert(error)
  })
  }
}
  return (
    <div className='popup'>
       <button onClick={props.closePopup}>X</button>
      <div className='popup_inner'>
      <div className="form p-4 me-5">
      <h3 className="py-2">Request Callback</h3>
      {error&&<p style={{color:'red', fontSize:"13px", textAlign:"left", marginLeft:"30px"}}>Please, fill mandatory fields</p>}
      <form className="text-start">
        <div className="mb-3 form-item">
          <label htmlFor="name" className="form-label required">
            Name
          </label>
          <input type="text" className="form-control" id="name" name="name" value = {name} placeholder='Enter full name' onChange = {updateNameVal} required/>
          {!isInputNameValid && (<div style={{ color: "#F61C04", marginTop:"-5px", fontSize:"12px" }}>enter valid name.</div>)}
        </div>
        <div className="mb-3 form-item">
          <label htmlFor="phone" className="form-label required">
            Phone number
          </label>
          <input type="text" className="form-control" id="phone" name="phone" value = {phone} onChange = {updatePhoneVal} placeholder='Enter phone number' required/>
          {!isInputphoneValid && (<div style={{ color: "#F61C04", marginTop:"-5px", fontSize:"12px"}}>Phone is not valid.</div>)}
        </div>
        <div>
          <button type="button" onClick={requestcall} className="btn btn-primary w-100 py-2">
            Submit
          </button>
        </div>
      </form>
    </div>
      </div>
    </div>
  );
}
class BackBtn extends React.Component{
    constructor() {
        super();
        this.state = {
          showPopup: false
        };
      }
      togglePopup() {
        this.setState({
          showPopup: !this.state.showPopup
        });
      }
    render() {
    return (
        <div className='callBackBtn'>
        <button  onClick={this.togglePopup.bind(this)}>Request a <br />call back</button>
        {this.state.showPopup ? 
          <Popup
            text='Close Me'
            closePopup={this.togglePopup.bind(this)}/>
          : null
        }
        </div>
    );
  }
};
function callBackBtn() {
    return (
        <>
        <BackBtn />
        </>
    )
}
export default callBackBtn;