import React, {useEffect} from "react";
import TopHead from "../components/Blog/topHead";
import NewBlog from "../components/Blog/newBlog";
import SuggestedBlog from "../components/Blog/suggestedBlog";
import RecommendedBlogs from "../components/Blog/recommendedBlogs";


function Blog() {
	useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
	return (
		<div>
			<TopHead />
			<NewBlog />
			<SuggestedBlog />
			{/* <RecommendedBlogs /> */}
		</div>
	);
}
export default Blog;
