import React, {useEffect}  from "react";
import TopHead from "../components/PersonalCare/tophead";
import BeyondQR from "../components/PersonalCare/beyondqr";
import Solution from "../components/PersonalCare/solution";
import BoostDemo from "../components/PersonalCare/boostdemo";
import { Helmet } from 'react-helmet';

export default function PersonalCarePage() {
    useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
    return (
      <>
        <Helmet>
            <meta name="description" content="This is a description of my page" />
            <meta name="keywords" content="Ciphercode, Personal Care" />
            <meta property="og:title" content="Beyond QR Codes" />
            <meta property="og:description" content=" Discover - How the Product Digitization technology is transforming the beauty and wellness industry in the hyper-personalization era." />
            <meta property="og:image" content="/images/Elemage1.png" />
            <meta property="og:url" content="https://www.ciphercode.co/personalcare" />
            <meta name="twitter:title" content="Beyond QR Codes" />
            <meta name="twitter:description" content=" Discover - How the Product Digitization technology is transforming the beauty and wellness industry in the hyper-personalization era." />
            <meta name="twitter:image" content="/images/Elemage1.png" />
            <meta name="twitter:card" content="/images/Elemage1.png" />
            <title>Ciphercode-Beyond QR Codes</title>
        </Helmet>
        <TopHead />
        <BeyondQR />
        <Solution />
        <BoostDemo />
      </>
    )
}
