import React, {useEffect} from "react";
import Faqs from "../components/ContactUs/faqs";
import Footer from "../components/ContactUs/footer";
import TopHead from "../components/ContactUs/top-head";


export default function Contactus() {
	useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
	return (
		<div>
			<TopHead />
            <Faqs />
            <Footer />
		</div>
	);
}

