import React, {useState} from "react";
import './tophead.css'
import Tophead from "./tophead";
import {AiOutlineDownload} from "react-icons/ai";
import { MdContentCopy } from "react-icons/md";
import CopyToClipboard from "react-copy-to-clipboard";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink} from "react-router-dom";
import QRCode from 'qrcode.react';
import dummyimage from '../../images/QR_Code.png';
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';
import { FaLink, FaPaperclip, FaMapMarkerAlt, FaWhatsapp , FaUsers} from "react-icons/fa";


const ShortURL = () => {
  const [url, SetLongurl] = useState("");
  const [shortenLink, setShortenLink] = useState("");
  const [qrCodeText, setQRCodeText] = useState('');
  const [copied, setCopied] = useState(false);
  const[error,seterror]=useState(false);

  const short = async () => {
    if (url.trim("").length === 0) {
      seterror(true)
    }
    else if(url.trim("").length>0) {
      seterror(false)
      let item = {url}
      fetch("https://test.ciphercode.co/api/v1/shorturl/", {
        method : 'POST',
        body: JSON.stringify(item),
        headers : {
            "Content-Type" : 'application/json',
            "Accept" : 'application/json',
        }
      })
    .then(response => response.json())
    .then(response => { 
      setShortenLink(response);
      setQRCodeText(url);
    })
  }
  }
  const notify = () => toast("Copied");
  // download QR code
  const downloadQRCode = async () => {
    const qrCodeURL = document.getElementById('qrCodeEl')
    const canvas = await html2canvas(qrCodeURL);
    const dataURL = canvas.toDataURL('image/png');
    downloadjs(dataURL, 'qr_code.png', 'image/png');
  };
    return (
      <>
        <Tophead />
        <div style={{background: "linear-gradient(0.63deg, rgba(49, 87, 239, 0.1) 1.58%, rgba(49, 87, 239, 0) 97.39%)", textAlign: '-webkit-center', paddingBottom:'20px'}}>
          <div className="container brandwiseqrbox">
          <div className="nav">
            <input type="checkbox" id="nav-check" />
            <div className="nav-btn">
             <label htmlFor="nav-check">QR Menu</label>
            </div>
            <div className="nav-links">
               <NavLink exact="true" activeclassname ="active" to="/qrcode/url"><FaLink/> URL</NavLink>
               <NavLink exact="true" activeclassname ="active" to="/qrcode/shorturl"><FaPaperclip/>  Short URL</NavLink>
               <NavLink exact="true" activeclassname ="active"to="/qrcode/whatsapp"><FaWhatsapp /> WhatsApp</NavLink>
               <NavLink exact="true" activeclassname ="active"to="/qrcode/social_meida"><FaUsers /> Social Media</NavLink>
               {/* <NavLink exact="true" activeclassname ="active" to="/qrcode/File_qr"><FaFilePdf /> PDF/Media</NavLink> */}
               <NavLink exact="true" activeclassname ="active"to="/qrcode/location_qr"><FaMapMarkerAlt /> Location</NavLink>
               {/* <NavLink exact="true" activeclassname ="active" to="/qrcode/webform_qr"><FaWpforms /> Web Forms</NavLink> */}
               {/* <NavLink exact="true" activeclassname ="active" to="/qrcode/campaign"><MdOutlineCampaign /> Campaign</NavLink> */}
            </div>
          </div>
           <div className="row brandqr">
            <div className="col-md-7 order-md-2">
              <div className="qr_data shorturl">
              <label htmlFor="exampleInputurl" style = {{cursor:"pointer"}}>Enter Long URL</label><br /> 
              <input type="url" className="form-control" id="exampleInputurl" placeholder='Paste the long url here' onChange = {(e) => {SetLongurl(e.target.value)}} />
              {error&&<p style={{ color: "#F61C04", marginTop:'-5px', fontSize:'18px', marginLeft:'5px' }}>field is required</p>}<br />
              {shortenLink && (
                <div className="result">
                 <p>{shortenLink}</p>
                 <CopyToClipboard text={shortenLink} onCopy={() => setCopied(true)}>
                 <button className={copied ? "copied" : ""} onClick={notify}><MdContentCopy /></button>
                 </CopyToClipboard>
                </div> 
              )}
              <button type="button" className="btn btn-blue text-center" onClick={short}>Submit</button>
              <ToastContainer />
              </div>
            </div>
            <div className="col-md-5 order-md-2">
              <div className='qr_code'>
                <div className="qrcodeimg" id="qrCodeEl">
                {qrCodeText ? <QRCode  value={qrCodeText} renderAs="canvas" fgColor={"#000"} size ={300} includeMargin/>
                : <img src={dummyimage} /> }
                </div><br />
                <button type="button" className="download-btn" onClick={downloadQRCode} value="Download" title="copy url"><AiOutlineDownload /> Download (.PNG)</button>
              </div>
            </div>
        </div>
      </div>
    </div>
    </>
  );
};
export default ShortURL;