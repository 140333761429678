import React  from 'react';
import './webplatform2.css'


function WebPlatfrom2() {
return (  
    <div className="webplatform2">
   <div className="container">
         <div className="million-view">
            <h1>One Million +</h1>
            <p>Get millions of consumers engaged everyday</p>
         </div>
   </div>
   </div> 
)
}
export default WebPlatfrom2