import React  from 'react';
import img6 from "../../images/circle_left.svg";
import img4 from "../../images/polygon-72.svg";
import img3 from "../../images/rectangle_4.png";
import img2 from "../../images/rectangle_3.png";
import img1 from "../../images/rectangle_2.png";
import img5 from "../../images/rectangle_1.png";
import imgcir1 from "../../images/circle1.png";
import imgcir2 from "../../images/freework1.png";
import imgcir3 from "../../images/circle3.png";
import imgcir4 from "../../images/circle4.png";
import imgcir5 from "../../images/circle5.png";
import imgcir6 from "../../images/circle6.png";
import imgcir7 from "../../images/circle7.png";
import imgrect from "../../images/on-chain.png";
import {Link} from "react-router-dom";
import './webplatform6.css'

function WebPlatfrom6() {
    return (  
    <div className="webplatform6">
        <div className="semicircle_left">
          <img className="profiles-icon" alt="" src={img6} />
        </div>
        <div className="management_circle container">
            <div className="row">
            <div className="col ellipse-btw">
            <img className="profiles-icon" alt="" src={img5} />
            <h6>Physical Token</h6>
            </div>
            <div className="col ellipse-btw">
            <img className="profiles-icon" alt="" src={img3} />
            <h6>Physical Product <br></br>Auth, at scale</h6>
            </div>
            <div className="col ellipse-btw">
            <img className="profiles-icon" alt="" src={img2} />
            <h6>Consumer <br></br>Intelligence</h6>
            </div>
            <div className="col ellipse-btw">
            <img className="profiles-icon" alt="" src={img1} />
            <h6>Test Reports Auth.</h6>
            </div>
            </div>
          
            <div className="row business_brand">
                <div className="col-12 col-md-3 snap_circle">
                <p>ERP</p>
                <span class="dot">
                  <img src={imgcir4} alt="" style={{width:"100%"}}/>
                </span>
                <span class="dot">
                <img src={imgcir3} alt="" style={{width:"90%", height:"15px"}}/></span><br></br>
                <span class="dot">
                <img src={imgcir2} alt="" style={{width:"100%", height:"50px"}} />
                </span>
                <span class="dot">
                <img src={imgcir1} alt="" style={{width:"100%"}}/>
                </span>
                <h6>CRM</h6>
            </div>
            <div className="col-12 col-md-6 brand_rectangle">
                <img src={imgrect} alt="" />
                <p>Blockchain Powered <br></br>Web Platform</p>
                <Link  to='/product'><button>Brandwise</button></Link>
            </div>
            <div className="col-12 col-md-3 snap_circle">
            <p>API Integration</p>
                <span class="dot">
                <img src={imgcir5} alt="" />
                </span>
                <span class="dot">
                <img src={imgcir6} alt="" style={{width:"100%", height:"40px"}} />
                </span><br></br>
                <span class="dot">
                <img src={imgcir7} alt="" />
                </span>
            <h6>POS</h6>
            </div>
            </div>
        </div>
        <div className="semiellipse_rigth">
           <img  alt="" src={img4} />
        </div>
    </div>
        )
    }
export default WebPlatfrom6