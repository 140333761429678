import React, {useState, useEffect} from "react";
import "./features.css";
import TableMarkup from "./Table";

const color = {
	Starter: "#EC7AAD",
	Lite: "#39E90E",
	Standard: "#686EFF",
	Premium: "#EC7A7A",
	Enterprise: "#FFCA7A",
};

function PricingSection({titles, data, initialopen}) {
	const [open, setopen] = useState(initialopen || false);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
	const [index, setIndex] = useState(1)

	const updateMedia = () => setIsMobile(window.innerWidth < 800);
	
	useEffect(() => {
		window.addEventListener("resize", updateMedia);
		return () => window.removeEventListener("resize", updateMedia);
	});

	const handleNext = () => {
		setIndex((index) => ((index)%(titles.length-1) + 1));
	}
	const handlePrev = () => {
		setIndex((index) => ((index + titles.length - 3) % (titles.length - 1)) + 1);
	}
	return (
		<div className="pricing-section">
			<div className={open ? "display-hide" : "btn d-flex flex-row align-items-center short"} onClick={() => setopen(true)}>
				<p className="fw-bold">{titles[0]}</p>
				<div className="ms-auto">
					<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle cx="11.7185" cy="11.4541" r="11.1827" transform="rotate(179.173 11.7185 11.4541)" fill="#6773F7" />
						<path d="M11.793 16.541L14.6072 11.4998L8.83431 11.5832L11.793 16.541ZM12.2279 12.0343L12.146 6.36064L11.1461 6.37508L11.228 12.0487L12.2279 12.0343Z" fill="white" />
					</svg>
				</div>
			</div>
			<div className={open ? "" : "display-hide"}>
				{isMobile && (
					<div className="d-flex flex-row justify-content-between align-items-center p-2 table-title">
						<button className="btn" onClick={handlePrev} disabled={index === 1}>
							<svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<circle cx="11.6827" cy="11.8603" r="11.1827" transform="rotate(-90 11.6827 11.8603)" fill={index === 1 ? "#F0F0F0" : "#6773F7"} />
								<path d="M6.5957 11.8594L11.5957 14.7461L11.5957 8.97262L6.5957 11.8594ZM11.0957 12.3594L16.7699 12.3594L16.7699 11.3594L11.0957 11.3594L11.0957 12.3594Z" fill="white" />
							</svg>
						</button>
						<div className="header" style={{"--pcolor": `${color[titles[index]]}`}}>
							{titles[index]}
						</div>
						<button className="btn" onClick={handleNext} disabled={index === titles.length - 1}>
							<svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<circle cx="11.5771" cy="11.8604" r="11.1827" transform="rotate(90 11.5771 11.8604)" fill={index === titles.length - 1 ? "#F0F0F0" : "#6773F7"} />
								<path d="M16.6641 11.8613L11.6641 8.97458L11.6641 14.7481L16.6641 11.8613ZM12.1641 11.3613L6.48985 11.3613L6.48985 12.3613L12.1641 12.3613L12.1641 11.3613Z" fill="white" />
							</svg>
						</button>
					</div>
				)}
				<TableMarkup titles={titles} data={data} color={color} handleOnClick={() => setopen(false)} showonly={isMobile && [0, index]} />
			</div>
		</div>
	);
}

export default function Features() {	
	return (
		<div className="container features text-center">
			<h1>Features</h1>
			<p>Curated for every business need.</p>
			<PricingSection
				titles={["Overall Features", "Starter", "Lite", "Standard", "Premium"]}
				data={[
					["Static QR", "FREE", "FREE", "FREE", "FREE",],
					["CryptoBlock QR", "5", "50", "550", "1200",],
					["Users", "1", "1", "2", "4"],
					["Messaging ", "NO", "50", "1500", "3000"],
					["IVR / Chatbot", "NO", "NO", "NO", "BILLED AT ACTUALS"],
					["Landing Page Design Studio", "NO", "NO", "NO", "NO"],
					["CRM Integration", "NO", "NO", "ON REQUEST", "ON REQUEST"],
					["URL Shortener", "NO", "YES", "YES", "YES"],
					["Engagement History", "NO", "NO", "YES", "YES"],
					["No.of URL Switch Per Year", "-", "-", "LIMITED", "UNLIMITED"],
				]}
				initialopen={true}
			/>
			<PricingSection
				titles={["QReach", "Starter", "Lite", "Standard", "Premium"]}
				data={[
					["Application-wise templates", "YES", "YES", "YES", "YES"],
					["Consumer forms / apps", "NO", "NO", "YES", "YES"],
					["Scan2Win", "NO", "NO", "YES", "YES"],
					["Feedback2Win", "NO", "YES", "YES", "YES"],
					["Social Media sharing", "NO", "YES", "YES", "YES"],
					["Export data (Pdf/Excel)", "NO", "NO", "YES", "YES"],
					["Dynamic content orchestration", "YES", "YES", "YES", "YES"],
					["Lead Management", "LIMITED", "YES", "YES", "YES"],
				]}
				initialopen={true}
			/>
			<PricingSection
				titles={["Qseal", "Starter", "Lite", "Standard", "Premium"]}
				data={[
					["Service Delivery", "1 YEAR", "1 YEAR", "1 YEAR", "1 YEAR"],
					["Item Level Authentication", "YES", "YES", "YES", "YES"],
					["Destination Market Assignment", "NO", "NO", "NO", "YES"],
					["Distribution Channel Management", "NO", "NO", "NO", "YES"],
					["Secure Document Download as a PDF With Cryptoblock Signature.", "NO", "NO", "YES", "YES"],
					["Batch Processing", "NO", "NO", "YES", "YES"],
					["Export Data (Pdf/Excel)", "NO", "NO", "YES", "YES"],
					["Integrate With Digilocker", "NO", "NO", "NO", "YES"],
				]}
			/>
			<PricingSection
				titles={["QReport", "Starter", "Lite", "Standard", "Premium"]}
				data={[
					["Service Delivery", "NO", "NO", "NO", "NO"],
					["One Time Setup Fee", "NO", "NO", "NO", "NO"],
					["Template Charges", "NO", "NO", "NO", "NO"],
					["Design & Save Custom Templates", "NO", "NO", "NO", "NO"],
					["Lead Generation", "NO", "NO", "NO", "NO"],
					["Integrate With Digilocker/Vault", "NO", "NO", "NO", "NO"],
				]}
			/>
			<PricingSection
				titles={["Reporting & Analytics", "Starter", "Lite", "Standard", "Premium"]}
				data={[
					["Email Reports", "NO", "NO", "NO", "NO"],
					["Google Analytics + Ground Insights", "NO", "NO", "YES", "YES"],
					["First Party Data & Retargeting Lists", "NO", "NO", "NO", "NO"],
				]}
			/>
			<PricingSection
				titles={["Data & Security", "Starter", "Lite", "Standard", "Premium"]}
				data={[
					["GDPR Compliant", "YES", "YES", "YES", "YES"],
					["Multi-user Access Management", "NO", "NO", "NO", "YES"],
					["SSO Integration", "NO", "NO", "NO", "NO"],
				]}
			/>
			<PricingSection
				titles={["Branding", "Starter", "Lite", "Standard", "Premium"]}
				data={[
					["QR Design Customization", "NO", "NO", "NO", "YES"],
					["License Solution", "NO", "NO", "NO", "NO"],
					
				]}
			/>
			<PricingSection
				titles={["Campaign Personalization", "Starter", "Lite", "Standard", "Premium"]}
				data={[
					["Age-gated QR Codes", "-", "-", "-", "-"],
					["Custom Domain/URL", "NO", "NO", "NO", "NO"],
					
				]}
			/>
			<PricingSection
				titles={["Scalability", "Starter", "Lite", "Standard", "Premium"]}
				data={[
					["Bulk QR Code Generation", "NO", "NO", "YES", "YES"],
					["Dynamic QR Code API", "NO", "NO", "YES", "YES"],
				]}
			/>
			<PricingSection
				titles={["Integrations", "Starter", "Lite", "Standard", "Premium"]}
				data={[
					["Zapier", "-", "-", "-", "-"],
					["Workato", "-", "-", "-", "-"],
					["Integromat","-", "-", "-", "-"],
					["Google Analytics", "NO", "NO", "YES", "YES"],
					["Slack", "-", "-", "-", "-"],
				]}
			/>
			<PricingSection
				titles={["Service Support", "Starter", "Lite", "Standard", "Premium"]}
				data={[
					["Live Chat",  "NO", "NO", "YES", "YES"],
					["Email Support",  "YES", "YES", "YES", "YES"],
					["Phone Support",  "NO", "NO", "NO", "YES"],
					["Customer Success Manager",  "NO", "NO", "NO", "NO"],
					["Training & Onboarding", "NO", "NO", "NO", "NO"],
				]}
			/>
		</div>
	);
}
