import React, {useEffect}  from "react";
import TopHead from "../components/Partnershipprogram/tophead";
import Partner from "../components/Partnershipprogram/partner";
import Feedback from "../components/Partnershipprogram/feedback";
import Discover from '../components/Partnershipprogram/discover';
import Footerhome from '../components/Partnershipprogram/footerhome';

export default function Partnershipprogram() {
    useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	});
    return (
      <>
         <TopHead />
         <Partner/>
         <Feedback/>
         <Discover/>
         <Footerhome/>
         
      </>
    )
}