import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import img1 from '../../images/home7.png';
import img2 from '../../images/home4.png';
import img3 from '../../images/home2.png';
import img4 from '../../images/home6.png';
import './discover.css';

export default function discover() {
    return (
        <div className="discover">
            <div className="discover-content container">
                <div className="row g-4">
                    <h2>Discover How <span> Brandwise</span> transforms your Industry.</h2>
                    <div className="col-md-3 ">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img2} />
                            <Card.Body>
                                <Card.Title>RETAIL INDUSTRY</Card.Title>
                                <Card.Text>
                                    Using our innovative campaign
                                    solutions, a partner of Brandwise was
                                    able to boost margins by ...
                                </Card.Text>
                                <a href="/retail"><Button variant="primary" >Know More</Button></a>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-3 ">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img1} />
                            <Card.Body>
                                <Card.Title>APPAREL INDUSTRY</Card.Title>
                                <Card.Text>
                                    An Apparel Retail Partner was able to
                                    boost their store footfall using Brandwise
                                    Engagement Solutions ...
                                </Card.Text>
                                <Button variant="primary">Know more</Button>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-3 ">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img3} style={{height:"90%"}}/>
                            <Card.Body>
                                <Card.Title>FOOTWEAR INDUSTRY</Card.Title>
                                <Card.Text>
                                    Implemented Omnichannel Engagement
                                    strategies to boost footfall and Digitize
                                    Products ...
                                </Card.Text>
                                <a href="/footwear"><Button variant="primary" >Know More</Button></a>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-3 ">
                        <Card className="custom-card">
                            <Card.Img variant="top" src={img4}  />
                            <Card.Body>
                                <Card.Title>PERSONAL CARE INDUSTRY</Card.Title>
                                <Card.Text>
                                    Brandwise is a Flexible solution that can
                                    solve requirements specific to your
                                    needs...
                                </Card.Text>
                                <a href="/personalcare"><Button variant="primary" >Know More</Button></a>
                            </Card.Body>
                        </Card>
                    </div>
                </div><br /><br /><br />

            </div>
        </div>
    )
}
