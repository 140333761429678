import React from 'react';
import img1 from "../../images/packaging1.png";
import img3 from "../../images/packaging2.png";
import img4 from "../../images/packaging3.jpg";
import './package.css';

export default function Package() {
	return (
		<div className="container packaging-content" id="id_packaging_content">
            <h2>How Packaging Partners leverage Brandwise</h2>
        <div className="row">
            <div className="col-md-4 order-md-2">
                <div className="packaging-content3">
                    <img src={img1} alt="" style={{width:'108%'}} />
                    <h2>Sustainable Packaging Initiatives</h2>
                    <ul style={{marginBottom:'22px'}}>
                        <li>Avoid reprints with dynamic content.</li>
                        <li>Attract consumers to participate in sustainable initiatives circular economy.</li>
                        <li>Bring intelligence across value chain to minimize waste.</li>
                        <li>Embedded recycle empowerments and more.</li>
                    </ul>
                </div>
            </div>
            <div className="col-md-4 order-md-2">
                <div className="packaging-content3">
                    <img src={img3} alt="" style={{width:'109%'}}/>
                    <h2>Bring the ecosystem together</h2>
                    <ul>
                        <li>Smart packaging is more than just a trend, it's a need reshaping the way we interact with products and brands.</li>
                        <li>Tech-enabled collaboration with ecosystem partners optimizes engagement to intelligence across levels.</li>
                    </ul><br></br><br/>
                </div>
            </div>
            <div className="col-md-4 order-md-2">
                <div className="packaging-content3">
                    <img src={img4} alt="" style={{width:'109%'}}/>
                    <h2>Personalized Packaging for your Partners</h2>
                    <ul style={{marginBottom:'28px'}}>
                        <li>Smart packaging isn't just about making products smarter; it's about making consumers more informed.</li>
                        <li>Create end-less content, experiences and deliver those experiences specific to SKU, Batch, Geography, Demography and other filters</li>
                    </ul>
                </div>
            </div>
        </div><br></br>
        </div>

    )
}