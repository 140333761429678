import React, { useState } from 'react';
import './partner.css';
import img1 from "../../images/partner5.png";
import LoadingOverlay from "react-loading-overlay";


export default function Partner() {
    const [contactName, setContactName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [designation, setDesignation] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [email, setEmail] = useState("");
    const [companyHqCity, setCompanyHqCity] = useState("");
    const [country, setCountry] = useState("");
    const [websiteUrl, setWebsiteUrl] = useState("");
    const [additionalNote, setAdditionalNote] = useState("");
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Simple validation logic
        if (!contactName || !companyName || !email) {
            setError(true);
            return;
        }

        setIsLoading(true);
        setError(false);

        const formData = new FormData();
        formData.append('contactName', contactName);
        formData.append('companyName', companyName);
        formData.append('designation', designation);
        formData.append('mobileNumber', mobileNumber);
        formData.append('email', email);
        formData.append('companyHqCity', companyHqCity);
        formData.append('country', country);
        formData.append('websiteUrl', websiteUrl);
        formData.append('additionalNote', additionalNote);

        try {
            const response = await fetch("https://web.ciphercode.co/api/v1/contactus/", {
                method: "POST",
                body: formData,
            });
            const result = await response.json();
            if (result.message === "Your data successfully submitted") {
                // Success alert
                alert("Thanks for contacting us! We'll respond shortly.");
                // Reset form fields
                setContactName("");
                setCompanyName("");
                setDesignation("");
                setMobileNumber("");
                setEmail("");
                setCompanyHqCity("");
                setCountry("");
                setWebsiteUrl("");
                setAdditionalNote("");
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <LoadingOverlay active={isLoading} spinner text='Submitting...'>
        <div className="container partner-content">
            <div className="form-content">
                <div className="row">
                    <div className="col-md-6">
                        <h2>Let's <span>work together</span> for
                            common customer <span>success.</span></h2>
                        <img src={img1} alt="" style={{ width: "100%", }} />
                    </div>
                    <div className="col-md-6">
                    <div className="card">
                    <div className="card-content form-content1">
                        <h3>Partner With Us</h3>
                        <form onSubmit={handleSubmit}>
                        {error && <div className="error-message" style={{color:"red"}}>Please fill in all required fields correctly.</div>}
                            <div className="row px-1">
                                <div className="col">
                                    <input type="text" className="mb-4" name="contactName" placeholder='Contact Name' value={contactName} onChange={(e) => setContactName(e.target.value)} />
                                </div>
                            </div>
                            <div className="row px-1">
                                <div className="col">
                                    <input type="text" className="mb-4" name="companyName" placeholder='Company Name' value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                                </div>
                                <div className="col">
                                    <input type="text" className="mb-4" name="designation" placeholder='Designation' value={designation} onChange={(e) => setDesignation(e.target.value)} />
                                </div>
                            </div>
                            <div className="row px-1">
                                <div className="col">
                                    <input type="text" className="mb-4" name="mobileNumber" placeholder='Mobile Number' value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
                                </div>
                            </div>
                            <div className="row px-1">
                                <div className="col">
                                    <input type="email" className="mb-4" name="email" placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                                </div>
                            </div>
                            <div className="row px-1 select-container">
                                <div className="col">
                                    <input type="text" className="mb-4" name="companyHqCity" placeholder='Company HQ City' value={companyHqCity} onChange={(e) => setCompanyHqCity(e.target.value)} />
                                </div>
                                <div className="col">
                                    <select className="mb-4" name="country" value={country} onChange={(e) => setCountry(e.target.value)}>
                                        <option value="">Country</option>
                                        <option value="Afghanistan">Afghanistan</option>
                                        <option value="India">India</option>
                                        {/* Add more countries as needed */}
                                    </select>
                                </div>
                            </div>
                            <div className="row px-1">
                                <div className="col">
                                    <input type="url" className="mb-4" name="websiteUrl" placeholder='Website URL' value={websiteUrl} onChange={(e) => setWebsiteUrl(e.target.value)} />
                                </div>
                            </div>
                            <div className="row px-1">
                                <div className="col">
                                    <textarea className="mb-4" name="additionalNote" placeholder='Additional Note' value={additionalNote} onChange={(e) => setAdditionalNote(e.target.value)}></textarea>
                                </div>
                            </div>
                            
                            <div className="row px-1">
                                <div className="col">
                                    <button type="submit" id="buttonsubmit" className="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </form>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </LoadingOverlay>
    );
}
