import React  from 'react';
import img6 from "../../images/icon.svg";
import img4 from "../../images/demoza.png";
import img5 from "../../images/aditya_birla.png";
import img7 from "../../images/vanheusen.png";
import img8 from "../../images/asset_pyramid.png";
import img9 from "../../images/group-155.svg";
import {Link} from "react-router-dom";
import { BsArrowRightShort } from "react-icons/bs";
import './webplatform4.css'


function WebPlatfrom4() {
    return (  
    <div className="webplatform4">
        <div className="container">
           <div className="row">
            <div className="col-md-6">
               <div className="brand-box">
                    <div className="protect">
                    <img src={img6} alt="" />
                    </div>
                    <h4>100+ Registered Brands</h4>

                    <p className='tech-stack'>Coming Soon</p>
                    {/* <div className="brand-item">
                       <img src={img4} alt="" />
                       <div className="brand-name-details">
                       <h6>Brand Name 1</h6>
                       <p>Lorem ipsum about the brand</p>
                       </div>
                    </div>
                    <div className="brand-item">
                       <img src={img5} alt="" />
                       <div className="brand-name-details">
                       <h6>Brand Name 2</h6>
                       <p>Lorem ipsum</p>
                       </div>
                    </div>
                    <div className="brand-item">
                       <img src={img7} alt="" />
                       <div className="brand-name-details">
                       <h6>Brand Name 3</h6>
                       <p>Lorem ipsum</p>
                       </div>
                    </div> */}
                </div>
                <div className="cryptoblock-brand">
                    <img src={img8} alt="" />
                    <h6>Your Brand is Just a <br></br>Cryptoblock away</h6>
                    <p>Activate your comsumer magnet</p>
                    <Link  to='/contactus'><button>Try it yourself <BsArrowRightShort /></button></Link> 
                </div>
            </div>
            <div className="col-md-6">
                <div className="ciphercode-info">
                    <h1>Why <span className='tech-stack'>Choose</span> <br></br>Ciphercode ?</h1>
                    <p>Decentralized world evolves around consumers, Consumers needs engaging channels to exercise their trust, digital authentication is the channel waiting to be exploded, get onboard with us to be tomorrow’s business!</p>
                    <div className="cipher-item">
                       <img src={img9} alt="" />
                       <p>World’s best product authentication digital stack</p>
                    </div>
                    <div className="cipher-item">
                       <img src={img9} alt="" />
                       <p>Patented yet evolving technology</p>
                    </div>
                    <div className="cipher-item">
                       <img src={img9} alt="" />
                       <p>App-less consumer engagement, limit-less brands leverage</p>
                    </div>
                    <div className="cipher-item">
                       <img src={img9} alt="" />
                       <p>Brands Pass to web 3.0</p>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
)
}
export default WebPlatfrom4
