import React, {useEffect} from "react";
import TopHead from "../components/AboutUs/tophead";
import Aboutme  from "../components/AboutUs/aboutme";
import Footertwo from '../components/Home/footertwo';

function About() {
  useEffect(() => {
		window.scroll({top: 0, behavior: 'smooth'})
	  });
	return (
    <>
      <TopHead></TopHead>
      <Aboutme></Aboutme>
      <Footertwo></Footertwo>
    </>
    )
}
export default About;