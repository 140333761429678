import React from "react";
import './table.css'
import cross from '../../images/cross.png'

const TableMarkup = ({titles, data, color, handleOnClick, showonly}) => {
	console.log(showonly);

	titles = titles.filter((value, index) => {
		if (!showonly || showonly.includes(index)) return true;
		return false
	})

	let finaldata = data.slice()
	data.forEach((row, i) => {
		finaldata[i] = row.filter((value, index) => {
			if (!showonly || showonly.includes(index)) return true;
			return false;
		})
	});
	return (
		<table>
			<colgroup>
				<col />
				<col />
				<col />
			</colgroup>
			<thead>
				<tr>
					{titles.map((title, index) => (
						<th key={index}>
							<div className="header">
								{(!showonly || !index) && <p style={{"--pcolor": `${color[title]}`}}>{title}</p>}
								{(!showonly || index==1) && (showonly || !index) && (
									<div className="btn close-btn" onClick={handleOnClick}>
										<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
											<circle cx="11.3702" cy="11.3096" r="11.1827" fill="#6773F7" />
											<path d="M11.3701 6.22217L8.48337 11.2222L14.2569 11.2222L11.3701 6.22217ZM10.8701 10.7222L10.8701 16.3964L11.8701 16.3964L11.8701 10.7222L10.8701 10.7222Z" fill="white" />
										</svg>
									</div>
								)}
							</div>
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{finaldata.map((item, index) => (
					<tr key={index}>
						{item.map((value, i) => (
							<td key={i}>
								{value == "NO" ? (
									<img src={cross} alt="NO" />
								) : value == "YES" ? (
									<svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M6.9832 13.5702C6.79262 13.5704 6.60388 13.533 6.42781 13.4601C6.25174 13.3871 6.09181 13.2801 5.9572 13.1452L1.2942 8.48322C1.02203 8.21093 0.869141 7.84171 0.869141 7.45672C0.869141 7.07173 1.02203 6.7025 1.2942 6.43022C1.56649 6.15805 1.93572 6.00516 2.3207 6.00516C2.70569 6.00516 3.07492 6.15805 3.3472 6.43022L6.9832 10.0662L16.2142 0.835218C16.4865 0.563049 16.8557 0.410156 17.2407 0.410156C17.6257 0.410156 17.9949 0.563049 18.2672 0.835218C18.5394 1.1075 18.6923 1.47673 18.6923 1.86172C18.6923 2.24671 18.5394 2.61593 18.2672 2.88822L8.0092 13.1452C7.8746 13.2801 7.71467 13.3871 7.5386 13.4601C7.36253 13.533 7.17378 13.5704 6.9832 13.5702Z" fill="black" />
									</svg>
								) : (
									value
								)}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default TableMarkup;